import { useEffect, useRef, useState } from 'react'
import Input from 'Components/Input/Input'
import classes from './CourseReading.module.css'
import SectionsNav4 from 'Components/SectionsNav4/SectionsNav4'
import BulkUploadComponent from './BulkUploadComponent'
import BuildFromScratch from './BuildFromScratch'
import { uploadReadingData } from 'Utilities/types'
import { inputChangeHandler } from 'HelperFunctions/inputChangeHandler'
import { mutate } from 'swr'
import { backend_url } from 'Utilities/global'
import { useParams, useSearchParams } from 'react-router-dom'
import { useLessonById } from 'Hooks/useTutorCourses'
import Loader from 'Components/Loader/Loader'
import {
  MDXEditor,
  type MDXEditorMethods,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  ListsToggle,
  InsertImage,
  InsertFrontmatter,
  CreateLink,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import Button from 'Components/Button/Button'
import { TrashIcon } from '@heroicons/react/24/outline'
import { addReadingContentToWeekById } from 'Services/Courses'
import errors from 'Utilities/errors'
import { useApp } from 'Context/AppContext'
import endpoints from 'Services/Courses/endpoints'

type TProp = {
  edit?: boolean
}
const Reading = ({ edit }: TProp) => {
  const ref = useRef<MDXEditorMethods>(null)
  // States
  // const [navItems, setNavItems] = useState<any[]>([
  //   {
  //     title: 'Upload Content',
  //     isActive: false,
  //   },
  //   {
  //     title: 'Type content',
  //     isActive: true,
  //   },
  // ])

  // // Formdata
  // const [readingFormData, setReadingFormData] = useState(new FormData())

  // // Router
  // const [searchParams] = useSearchParams()
  // const lessonId = searchParams.get('lesson-id')

  // // Request
  // const { isLoading, data } = useLessonById(lessonId as string, 'reading')
  // const readingContent = data?.data

  // Effects
  // useEffect(() => {
  //   const newFormData = new FormData()
  //   newFormData.append('title', readingData.title)
  //   if (readingData?.reading_contents) {
  //     newFormData.append(
  //       'reading_contents',
  //       readingData?.reading_contents as string
  //     )
  //   }

  //   newFormData.append('resources', readingData.resources?.toString())

  //   if (readingData.file) {
  //     Array.from(readingData?.file as FileList)?.forEach((file) => {
  //       newFormData.append('files', file)
  //     })
  //   }

  //   setReadingFormData(newFormData)

  //   // eslint-disable-next-line
  // }, [readingData])

  // useEffect(() => {
  //   if (edit) {
  //     mutate(`${backend_url}/api/ise/v1/reading/${lessonId as string}`)
  //   }
  // }, [])

  // useEffect(() => {
  //   if (readingContent) {
  //     setReadingData({
  //       title: readingContent?.title,
  //       reading_contents: readingContent?.reading_contents,
  //       file: null,
  //       resources: [],
  //     })
  //   }
  // }, [readingContent])
  const isLoading = false

  const [readingData, setReadingData] = useState({
    title: '',
    // reading_contents: null,
    // file: null,
    resources: 'www.url',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { courseId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setNotifications } = useApp()
  const weekId = searchParams.get('week-id')

  if (isLoading) {
    return (
      <div className={classes.container}>
        <Loader />
      </div>
    )
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)

      const formData = new FormData()

      Object.entries(readingData).forEach(([key, value]) => {
        console.log(key, value)
        formData.append(key, value?.toString() || '')
      })

      formData.append('reading_contents', ref.current?.getMarkdown() || '')

      await addReadingContentToWeekById(weekId as string, formData)

      mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId as string))

      setNotifications([
        {
          title: 'Successfully added lesson to week',
          severity: 'success',
          id: Date.now(),
        },
      ])

      setSearchParams((prevState) => {
        prevState.delete('lesson-type')
        prevState.set('lesson-step', '1')
        return prevState
      })
    } catch (err) {
      const errorMessage = errors.format(err)

      setNotifications([
        { title: errorMessage, severity: 'error', id: Date.now() },
      ])
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className={classes.container}>
      <h1>{readingData.title || 'Untitled lesson'}</h1>
      <div className={classes.addLesson}>
        <Input
          isRequired
          label="Add lesson title"
          placeholder="Untitled lesson"
          errorMessage="Please enter a valid lesson name"
          name="title"
          value={readingData.title}
          onChange={(e) => inputChangeHandler(e, setReadingData)}
        />
      </div>

      <div className={`${classes.addLesson} ${classes.body}`}>
        <section className={classes.subContainer}>
          <div>
            <div className="bg-white pt-8 pb-10 px-8 rounded-lg">
              <h2 className="text-[25px] font-medium mb-8">
                Add reading content
              </h2>

              <div className="border rounded-md h-[400px]">
                <MDXEditor
                  plugins={[
                    // Example Plugin Usage
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    markdownShortcutPlugin(),
                    toolbarPlugin({
                      toolbarClassName: '!bg-white !border-b !rounded-b-none',
                      toolbarContents: () => (
                        <>
                          <BoldItalicUnderlineToggles />
                          <ListsToggle />
                          <InsertFrontmatter />
                          <UndoRedo />
                        </>
                      ),
                    }),
                  ]}
                  ref={ref}
                  markdown="Enter your reading content here"
                  onChange={console.log}
                />
              </div>
            </div>

            <div className="bg-white py-5 flex justify-between px-8 rounded-lg mt-5">
              <button className="flex items-center font-medium text-rose-500 gap-x-3">
                <TrashIcon />
                Delete lesson
              </button>

              <div className="flex gap-x-5">
                <Button type="secondary">Discard changes</Button>
                <Button onClick={handleSubmit} loading={isSubmitting}>
                  Save lesson
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Reading
