const endpoints = {
  GET_TUTOR_COURSES: '/tutors/my-courses',
  GET_COURSE_BY_ID: (id: string | number) => `/courses/${id}/course-details`,
  GET_CURRICULUM_BY_COURSE_ID: (id: string | number) =>
    `/tutors/${id}/curriculum`,
  COURSE_MODULE: (courseId: string) => `/course-modules/${courseId}`,
  ADD_COURSE_WEEKS: (moduleId: string) => `/course-weeks/${moduleId}`,
  UPDATE_COURSE_WEEKS: (weekId: string) => `/course-weeks/update/${weekId}`,
  GET_COURSE_MODULE_BY_ID: (moduleId: string) => `/course-modules/${moduleId}`,
  GET_COURSE_WEEK_BY_ID: (weekId: string) => `/course-weeks/${weekId}`,
  DELETE_COURSE_WEEK_BY_ID: (weekId: string) => `/course-weeks/${weekId}`,
  DELETE_COURSE_MODULE_BY_ID: (moduleId: string) =>
    `/course-modules/${moduleId}`,
  ADD_READING_CONTENT_TO_WEEK_BY_ID: (weekId: string) => `/reading/${weekId}`,
  ADD_PRESENTATION_CONTENT_TO_WEEK_BY_ID: (weekId: string) =>
    `/presentation/${weekId}`,
}

export default endpoints
