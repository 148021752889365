import Button from 'Components/Button/Button'
import Input from 'Components/Input/Input'
import Loader from 'Components/Loader/Loader'
import TextArea from 'Components/TextArea/TextArea'

import classes from './CurriculumModule.module.css'
import { useEffect, useState } from 'react'
import { inputChangeHandler } from 'HelperFunctions/inputChangeHandler'
import { useParams, useSearchParams } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/24/outline'
import { addCourseModule, updateCourseModule } from 'Services/Courses'
import errors from 'Utilities/errors'
import { useApp } from 'Context/AppContext'
import { mutate } from 'swr'
import endpoints from 'Services/Courses/endpoints'
import useCourse from 'Services/Courses/Hooks/useCourse'

const CloseIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18.5L18 6.5M6 6.5L18 18.5"
        stroke="#664EFE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

const ArrowRight = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8L21 12M21 12L17 16M21 12L3 12"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

const InfoIcon = () => {
  return (
    <svg
      onClick={() => {}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 16H12V12H11M12 8H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke="#2E2E2E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

const getPreviousSearchParams = (prevSearchParamState: URLSearchParams) => {
  return Object.fromEntries(prevSearchParamState.entries())
}

const CurriculumModule = () => {
  const isLoading = false
  const edit = false

  const [moduleData, setModuleData] = useState({
    title: '',
    description: '',
  })

  const [objective, setObjective] = useState([''])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { curriculumId, courseId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const moduleId = searchParams.get('module-id')
  const { setNotifications } = useApp()
  const { useGetCourseModuleById } = useCourse()
  const { data: courseModuleResponse } = useGetCourseModuleById(
    moduleId || null
  )

  useEffect(() => {
    if (courseModuleResponse?.data) {
      setModuleData({
        title: courseModuleResponse?.data?.title,
        description: courseModuleResponse?.data.description,
      })
      setObjective(courseModuleResponse?.data.objective)
    }
  }, [courseModuleResponse?.data])

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)

      if (moduleId) {
        // handle update here
        await updateCourseModule(moduleId, {
          ...moduleData,
          objective,
        })

        mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId || ''))

        setNotifications([
          {
            title: 'Successfully updated module',
            severity: 'success',
            id: Date.now(),
          },
        ])

        setSearchParams((prevState) => {
          const prev = getPreviousSearchParams(prevState)

          return {
            ...prev,
            'curriculum-step': '2',
          }
        })

        return
      }
      // createModule
      const { data: response } = await addCourseModule(curriculumId || '', {
        ...moduleData,
        objective,
      })

      mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId || ''))

      setNotifications([
        {
          title: 'Successfully created module',
          severity: 'success',
          id: Date.now(),
        },
      ])

      setSearchParams((prevState) => {
        const prev = getPreviousSearchParams(prevState)

        return {
          ...prev,
          'curriculum-step': '2',
          'module-id': response.id.toString(),
        }
      })
    } catch (err) {
      const errorMessage = errors.format(err)

      setNotifications([
        { title: errorMessage, severity: 'error', id: Date.now() },
      ])
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isLoading) {
    return (
      <div className={classes.addModules}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={classes.addModules}>
      <h3>{moduleId ? 'Edit' : 'Add'} module details </h3>
      <p>Help students understand a module clearly with additional details.</p>
      <Input
        isRequired
        label="Add module title"
        placeholder="E.g Module 1: Introduction to Frontend Development"
        name="title"
        value={moduleData.title}
        onChange={(e) => {
          inputChangeHandler(e, setModuleData)
        }}
        tip="Give modules names for organisation and easy learning "
      />

      <TextArea
        isRequired
        label="Module description"
        placeholder="E.g In this module, you will learn about the different types of web developers and the roles and responsibilities of front-end,
            back-end, and full-stack developers. You will take a first look at the core technologies of HTML, CSS, and Javascript and 
           explore the concepts that underpin how the internet works."
        name="description"
        value={moduleData?.description}
        onChange={(e) => {
          inputChangeHandler(e, setModuleData)
        }}
        tip="Summarise what the module includes for easy understanding "
      />

      {objective?.map((data, i) => {
        return (
          <div className={classes.inputSet}>
            <Input
              key={i}
              isRequired
              label={
                i === 0
                  ? 'Module objective * (Write a list of  what your students should learn at the end of this module) '
                  : ''
              }
              placeholder="E.g Understand the basics of frontend development"
              onChange={(e) => {
                setObjective((prevState) => {
                  const updatedDescription = [...prevState]
                  updatedDescription[i] = e.target.value
                  return updatedDescription
                })
              }}
              value={data}
            />
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setObjective((prevState) => {
                  if (prevState.length > 1) {
                    const filteredArray = prevState?.filter(
                      (filter: string) => {
                        return filter !== data
                      }
                    )

                    return filteredArray
                  } else {
                    return ['']
                  }
                })
              }}
            >
              <path
                d="M6 18.5L18 6.5M6 6.5L18 18.5"
                stroke="#2E2E2E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )
      })}

      <Button
        type="null"
        onClick={() => {
          setObjective((prevState) => {
            if (prevState.length > 0) {
              return [...prevState, '']
            } else {
              return ['']
            }
          })
        }}
      >
        <PlusIcon />
        <span>Add more objectives</span>
      </Button>

      <div className={classes.addModulesBottom}>
        <div>
          <InfoIcon />
        </div>

        <Button type="secondary" onClick={() => {}}>
          <CloseIcon />
          <span>Close</span>
        </Button>

        <Button
          type="primary"
          loading={isSubmitting}
          disabled={!moduleData?.description || !moduleData?.title}
          onClick={handleSubmit}
        >
          <span>Save and continue</span>
          <ArrowRight />
        </Button>
      </div>
    </div>
  )
}

export default CurriculumModule
