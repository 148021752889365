import axios from 'axios'
import endpoints from './endpoints'

export type TNullable<T> = T | null

type TCohort = {
  id: 25
  created_at: TNullable<string>
  update_at: TNullable<string>
  deleted_at: TNullable<string>
  name: TNullable<string>
  commitment: TNullable<string>
  location: TNullable<string>
  certification: boolean
  start_date: TNullable<string>
  end_date: TNullable<string>
  duration: TNullable<string>
  price: number
  total_capacity: TNullable<string>
  has_installment: boolean
  paid: boolean
  application_deadline: TNullable<string>
  slug: TNullable<string>
  status: TNullable<string>
  has_discount: boolean
  discount_percentage: number
  coupon_code: TNullable<string>
}

export type TTutor = {
  id?: number
  created_at?: TNullable<string>
  update_at?: TNullable<string>
  deleted_at?: TNullable<string>
  first_name: TNullable<string>
  last_name: TNullable<string>
  profile_image: TNullable<string>
  gender: TNullable<string>
  is_default_password?: boolean
  onboarding_complete?: boolean
  phone_number?: TNullable<string>
  email?: TNullable<string>
  linkedIn_profile: TNullable<string>
  twitter_profile?: TNullable<string>
  bio: TNullable<string>
  headline: TNullable<string>
  country: TNullable<string>
  state: TNullable<string>
  dob?: TNullable<string>
  specialization?: TNullable<string>
  years_of_experience?: TNullable<string>
  tech_proficiency?: TNullable<string>
  certifications?: TNullable<string>
  email_changed_at?: TNullable<string>
  status?: TNullable<string>
  deactivationReason?: TNullable<string>
  cohorts?: TNullable<TCohort[]>
}

export const axiosInstance = axios.create({
  baseURL: 'https://schoolstaging.iseschool.co/api/ise/v1',
})

export const fetcher = <T>(url: string) => {
  return axiosInstance.get<T | any>(url)
}

axiosInstance.interceptors.request.use(
  (request) => {
    const iseTutorAccessToken = localStorage.getItem('iseTutorAccessToken')
    request.headers.Authorization = `Bearer ${iseTutorAccessToken}`
    return request
  },
  (error) => {
    return error
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.reload()
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export const completeProfile = (payload: any) => {
  return axiosInstance.post(endpoints.ONBOARDING, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getProfile = (url: string) => {
  return axiosInstance.get<TTutor>(url).then((res) => res.data)
}

export const updateContactInfo = (payload: any) => {
  return axiosInstance.patch(endpoints.UPDATE_CONTACT_INFO, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

type TChangePasswordPayload = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

export const changePassword = (payload: TChangePasswordPayload) => {
  return axiosInstance.post(endpoints.CHANGE_PASSWORD, payload)
}

type TChangeEmailPayload = {
  email: string
  password: string
}

export const changeEmail = (payload: TChangeEmailPayload) => {
  return axiosInstance.post(endpoints.CHANGE_EMAIL, payload)
}
