import { useState } from 'react'
import Input from 'Components/Input/Input'
import classes from './CoursePresentation.module.css'
import DragAndDropInput from 'Components/DragAndDropInput/DragAndDropInput'
import Button from 'Components/Button/Button'
import { TrashIcon } from '@heroicons/react/24/outline'
import AcceptedModal from 'Components/Modals/AcceptedModal/AcceptedModal'
import DiscardModalBody from 'Containers/CreatingCourseModulePageContainer/DiscardModalBody'
import DeleteModalBody from 'Containers/CreatingCourseModulePageContainer/DeleteModalBody'
import errors from 'Utilities/errors'
import { useApp } from 'Context/AppContext'
import { addPresentationContentToWeekById } from 'Services/Courses'
import { useParams, useSearchParams } from 'react-router-dom'
import { mutate } from 'swr'
import endpoints from 'Services/Courses/endpoints'

const Presentation = () => {
  const [title, setTitle] = useState('')
  const [presentationContent, setPresentationContent] = useState<{
    file: File | null
    frontendFile: string
  }>({
    file: null,
    frontendFile: '',
  })
  const [displayDiscardModal, setDisplayDiscardModal] = useState(false)
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { setNotifications } = useApp()
  const [searchParams, setSearchParams] = useSearchParams()
  const { courseId } = useParams()
  const weekId = searchParams.get('week-id')

  // Formdata

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)

      const formData = new FormData()
      formData.append('title', title)
      formData.append('presentation_content', presentationContent.file as File)

      // create happens here
      await addPresentationContentToWeekById(weekId as string, formData)

      mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId as string))

      setNotifications([
        {
          title: 'Successfully added presentation to week',
          severity: 'success',
          id: Date.now(),
        },
      ])

      setSearchParams((prevState) => {
        prevState.delete('lesson-type')
        prevState.set('lesson-step', '1')
        return prevState
      })
    } catch (err) {
      const errorMessage = errors.format(err)

      setNotifications([
        { title: errorMessage, severity: 'error', id: Date.now() },
      ])
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <div className={classes.container}>
        <h1>{title || 'Untitled lesson'}</h1>

        <div className={classes.addLesson}>
          <Input
            isRequired
            label="Add lesson title"
            placeholder="Untitled lesson"
            errorMessage="Please enter a valid lesson name  "
            name="title"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </div>

        <div className={`${classes.addLesson} ${classes.body}`}>
          <section className={classes.subContainer}>
            <h2>Add presentation content</h2>
            <div className={classes.sectionsNavSection}>
              <div className={classes.bulkUpload}>
                <ul>
                  <li>There is a file size limit of 25MB</li>
                  <li>
                    Keep slides under 20 for increased student engagement.
                  </li>
                </ul>

                {!presentationContent.file && (
                  <>
                    <DragAndDropInput
                      acceptedFileTypes=".pptx,.pdf"
                      state={presentationContent}
                      setState={setPresentationContent}
                    />
                    <p className={classes.info}>
                      You can upload files with the extensions: pptx, pdf
                    </p>
                  </>
                )}

                {presentationContent.file && (
                  <div className={classes.fileUpload}>
                    <h3>Uploaded File</h3>

                    <div className={classes.file}>
                      <div>
                        <p>{(presentationContent.file as File)?.name}</p>
                      </div>

                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          setPresentationContent({
                            file: null,
                            frontendFile: '',
                          })
                        }}
                      >
                        <path
                          d="M19.5 7L18.6327 19.1425C18.5579 20.1891 17.687 21 16.6378 21H8.36224C7.31296 21 6.44208 20.1891 6.36732 19.1425L5.5 7M10.5 11V17M14.5 11V17M15.5 7V4C15.5 3.44772 15.0523 3 14.5 3H10.5C9.94772 3 9.5 3.44772 9.5 4V7M4.5 7H20.5"
                          stroke="#DC362E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`${classes.addLesson} ${classes.buttonContainer}`}
              >
                <Button
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={!title || !presentationContent.file}
                >
                  <span>Save lesson</span>
                </Button>

                <Button
                  type="secondary"
                  onClick={() => {
                    setDisplayDiscardModal(true)
                  }}
                >
                  <span>Discard changes</span>
                </Button>
                <Button
                  onClick={() => {
                    setDisplayDeleteModal(true)
                  }}
                >
                  <TrashIcon />

                  <span>Delete lesson</span>
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* MOdals */}
      {displayDiscardModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayDiscardModal(false)
          }}
          body={
            <DiscardModalBody
              onClick={() => {
                setDisplayDiscardModal(false)
              }}
              onClick2={() => {
                setDisplayDiscardModal(false)
                setTitle('')
                setPresentationContent({ frontendFile: '', file: null })
              }}
            />
          }
        />
      )}

      {displayDeleteModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayDeleteModal(false)
          }}
          body={
            <DeleteModalBody
              onClick={() => {
                setDisplayDeleteModal(false)
              }}
            />
          }
        />
      )}
    </>
  )
}

export default Presentation
