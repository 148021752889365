import { fetcher } from 'Services'
import endpoints from '../endpoints'
import useSWR from 'swr'
import { TCourseModule, TCourseWeek } from '..'

const useGetTutorCourses = () => {
  return useSWR(endpoints.GET_TUTOR_COURSES, fetcher)
}
const useGetCourseById = (id: string | number) => {
  return useSWR(endpoints.GET_COURSE_BY_ID(id), fetcher)
}
const useGetCurriculumByCourseId = (id: string | number | null) => {
  return useSWR(!id ? null : endpoints.GET_CURRICULUM_BY_COURSE_ID(id), fetcher)
}
const useGetCourseModuleById = (id: string | null) => {
  return useSWR(
    !id ? null : endpoints.GET_COURSE_MODULE_BY_ID(id),
    fetcher<TCourseModule>
  )
}

const useGetCourseWeekById = (id: string | null) => {
  return useSWR(
    !id ? null : endpoints.GET_COURSE_WEEK_BY_ID(id),
    fetcher<TCourseWeek>
  )
}

const useCourse = () => {
  return {
    useGetTutorCourses,
    useGetCourseById,
    useGetCurriculumByCourseId,
    useGetCourseModuleById,
    useGetCourseWeekById,
  }
}

export default useCourse
