import { axiosInstance, TNullable, TTutor } from 'Services'
import endpoints from './endpoints'

export type TCurriculum = {
  id: TNullable<number>
  created_at: TNullable<string>
  update_at: TNullable<string>
  deleted_at: TNullable<string>
  title: TNullable<string>
  course_modules: TCourseModule[]
}

export type TCourseModule = {
  title: TNullable<string>
  objective: string[]
  description: TNullable<string>
  curriculums: TNullable<TCurriculum>
  tutor: TNullable<TTutor>
  slug: TNullable<string>
  id: number
  created_at: TNullable<string>
  update_at: TNullable<string>
  deleted_at: TNullable<string>
  position: number
  publish: boolean
  status: TNullable<string>
  course_weeks: TCourseWeek[]
}

export type TCourseWeek = {
  id: number
  created_at: TNullable<string>
  update_at: TNullable<string>
  deleted_at: TNullable<string>
  title: TNullable<string>
  publish: boolean
  course_modules?: TCourseModule
  content?: TLessonContent[]
}

export type TLessonContent = {
  id: number
  created_at: string
  update_at: string
  deleted_at: null | string
  title: string
  icon_image: null
  type: string
  position: number
  details: {
    id: number
    created_at: string
    update_at: string
    deleted_at: null
    title: string
    reading_contents: string
    position: number
    resources: string
    lesson_content_type: string
    publish: boolean
  }
}

export const addCourseModule = (
  courseId: string,
  payload: {
    title: string
    objective: string[]
    description: string
  }
) => {
  if (!courseId) throw new Error('Course id is required')

  return axiosInstance.post<TCourseModule>(
    endpoints.COURSE_MODULE(courseId),
    payload
  )
}

export const updateCourseModule = (
  courseId: string,
  payload: {
    title: string
    objective: string[]
    description: string
  }
) => {
  if (!courseId) throw new Error('Course id is required')

  return axiosInstance.put<TCourseModule>(
    endpoints.COURSE_MODULE(courseId),
    payload
  )
}

export const addCourseWeek = (
  moduleId: string,
  payload: {
    title: string
  }
) => {
  return axiosInstance.post<TCourseWeek>(
    endpoints.ADD_COURSE_WEEKS(moduleId),
    payload
  )
}

export const updateCourseWeek = (
  weekId: string,
  payload: {
    title: string
  }
) => {
  return axiosInstance.patch<TCourseWeek>(
    endpoints.UPDATE_COURSE_WEEKS(weekId),
    payload
  )
}

export const getCourseModuleById = (moduleId: string) => {
  return axiosInstance.get<TCourseModule>(
    endpoints.GET_COURSE_MODULE_BY_ID(moduleId)
  )
}

export const getCourseWeekById = (weekId: string) => {
  return axiosInstance.get<TCourseWeek>(endpoints.GET_COURSE_WEEK_BY_ID(weekId))
}

export const deleteCourseWeekById = (weekId: string) => {
  return axiosInstance.delete(endpoints.DELETE_COURSE_WEEK_BY_ID(weekId))
}

export const deleteCourseModuleById = (moduleId: string) => {
  return axiosInstance.delete(endpoints.DELETE_COURSE_MODULE_BY_ID(moduleId))
}

export const addReadingContentToWeekById = (
  weekId: string,
  payload: FormData
) => {
  return axiosInstance.post(
    endpoints.ADD_READING_CONTENT_TO_WEEK_BY_ID(weekId),
    payload
  )
}

export const addPresentationContentToWeekById = (
  weekId: string,
  payload: FormData
) => {
  return axiosInstance.post(
    endpoints.ADD_PRESENTATION_CONTENT_TO_WEEK_BY_ID(weekId),
    payload
  )
}
